import * as THREE from "three";

window.addEventListener("load", init, false);

const containerID = "visual";
let scene;
let camera;
let globe;
let fieldOfView;
let aspectRatio;
let WIDTH;
let HEIGHT;
let renderer;
let container;
let ambientLight;
let hemisphereLight;
let shadowLight;

function init() {
  createScene();
  createLights();
  createGlobe();

  loop();
}

function createScene() {
  container = document.getElementById(containerID);
  WIDTH = container.clientWidth;
  HEIGHT = container.clientHeight;

  scene = new THREE.Scene();
  scene.fog = new THREE.Fog(0x04070e, 100, 950);

  aspectRatio = WIDTH / HEIGHT;
  fieldOfView = 60;
  camera = new THREE.PerspectiveCamera(fieldOfView, aspectRatio, 60, 950);

  camera.position.x = 0;
  camera.position.y = 200;
  camera.position.z = 100;

  renderer = new THREE.WebGLRenderer({
    alpha: true,
    antialias: true,
  });

  renderer.setSize(WIDTH, HEIGHT);
  renderer.shadowMap.enabled = true;

  container.appendChild(renderer.domElement);

  window.addEventListener("resize", handleResize, false);
}

function handleResize() {
  WIDTH = container.clientWidth;
  HEIGHT = container.clientHeight;
  renderer.setSize(WIDTH, HEIGHT);
  aspectRatio = WIDTH / HEIGHT;
  camera.aspect = aspectRatio;
  camera.updateProjectionMatrix();
}

function createLights() {
  hemisphereLight = new THREE.HemisphereLight(0xaaaaaa, 0x000000, 0.2);

  ambientLight = new THREE.AmbientLight(0xffffff, 0.2);

  shadowLight = new THREE.DirectionalLight(0xffffff, 0.6);
  shadowLight.position.set(150, 350, 350);
  shadowLight.castShadow = true;
  shadowLight.shadow.camera.left = 300;
  shadowLight.shadow.camera.right = 400;
  shadowLight.shadow.camera.top = 400;
  shadowLight.shadow.camera.bottom = -400;
  shadowLight.shadow.camera.near = 1;
  shadowLight.shadow.camera.far = 1000;
  shadowLight.shadow.mapSize.width = 2048;
  shadowLight.shadow.mapSize.height = 2048;

  scene.add(hemisphereLight);
  scene.add(ambientLight);
  scene.add(shadowLight);
}

class Globe {
  constructor() {
    const geom = new THREE.CylinderGeometry(300, 300, 200, 20, 10);
    geom.applyMatrix4(new THREE.Matrix4().makeRotationX(-Math.PI / 2));
    geom.mergeVertices();

    this.waves = geom.vertices.map((vertex) => {
      return {
        y: vertex.y,
        x: vertex.x,
        z: vertex.z,
        // a random angle
        ang: Math.random() * Math.PI * 2,
        // a random distance
        amp: 5 + Math.random() * 5,

        speed: 0.002 + Math.random() * 0.008,
      };
    });

    var mat = new THREE.MeshPhongMaterial({
      color: 0x202842,
      flatShading: THREE.FlatShading,
    });

    this.mesh = new THREE.Mesh(geom, mat);
    this.mesh.receiveShadow = true;
  }

  moveWaves() {
    const vertices = this.mesh.geometry.vertices;

    vertices.forEach((vertex, index) => {
      // get the data associated to it
      var vprops = this.waves[index];

      // update the position of the vertex
      vertex.x = vprops.x + Math.cos(vprops.ang) * vprops.amp;
      vertex.y = vprops.y + Math.sin(vprops.ang) * vprops.amp;

      // increment the angle for the next frame
      vprops.ang += vprops.speed;
    });

    this.mesh.geometry.verticesNeedUpdate = true;
    this.mesh.rotation.z += 0.00005;
  }
}

function createGlobe() {
  globe = new Globe();

  // Position globe to the right bottom corner
  globe.mesh.position.x = 300;
  globe.mesh.position.y = 0;
  globe.mesh.position.z = -60;

  scene.add(globe.mesh);
}

function loop() {
  globe.moveWaves();

  renderer.render(scene, camera);

  requestAnimationFrame(loop);
}
