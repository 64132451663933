import fetch from "isomorphic-fetch";

// let API_URL = document.location.hostname + "/contact.php";/

const formID = "contact-form";
const errorInputClass = "form__input--error";
const errorMessageClass = "form__error-message";

const errorMessages = {
  globalError: "Something went wrong, please try again.",
  name: "Please enter your name",
  organization: "Please enter your organization name",
  email: "Please enter a valid email address",
  message: "Please enter a message",
};

window.addEventListener("load", () => new ContactForm(), false);

class ContactForm {
  constructor() {
    const submitButton = document.getElementById("contact-form-submit");
    submitButton.addEventListener(
      "click",
      this.onSubmitClick.bind(this),
      false
    );
  }

  /**
   * Handle forms after submit click
   * @param {*} event
   */
  onSubmitClick(event) {
    event.preventDefault();
    event.stopPropagation();

    this.resetErrors();
    const isValid = this.validateForm();

    if (isValid) {
      this.submitForm();
    }
  }

  /**
   * Submits form to API
   */
  submitForm() {
    const form = document.querySelector("form");

    fetch("contact.php", {
      method: "POST",
      body: new FormData(form),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {
          return this.renderConfirmation();
        }
        this.onSubmitFormFail(res);
      })
      .catch((error) => {
        this.onSubmitFormFail();
      });
  }

  onSubmitFormFail(res = {}) {
    const key = res.message || "globalError";
    this.renderError(document.getElementById(key), errorMessages[key]);
  }

  /**
   * Validates form and render error messages
   * @returns {boolean}
   */
  validateForm() {
    const nameDOM = document.getElementById("name");
    const organizationDOM = document.getElementById("organization");
    const emailDOM = document.getElementById("email");
    const messageDOM = document.getElementById("message");
    let isValid = true;

    if (nameDOM.value.length === 0) {
      this.renderError(nameDOM, errorMessages.name);
      isValid = false;
    }

    if (organizationDOM.value.length === 0) {
      this.renderError(organizationDOM, errorMessages.organization);
      isValid = false;
    }

    if (emailDOM.value.length === 0 || !this.validateEmail(emailDOM.value)) {
      this.renderError(emailDOM, errorMessages.email);
      isValid = false;
    }

    if (messageDOM.value.length === 0) {
      this.renderError(messageDOM, errorMessages.message);
      isValid = false;
    }

    return isValid;
  }

  /**
   * Checks if string is valid email
   * @param {string} email
   */
  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  /**
   * Removes all error warnings and class from inputs
   */
  resetErrors() {
    const errorInputs = document.getElementsByClassName(errorInputClass);
    Array.from(errorInputs).forEach((input) =>
      input.classList.remove(errorInputClass)
    );

    const errorMessages = document.getElementsByClassName(errorMessageClass);
    Array.from(errorMessages).forEach((node) => node.remove());
  }

  /**
   * Renders error message below Input element
   * @param {Node} inputNode //
   * @param {String} errorMessage
   */
  renderError(inputNode, errorMessage) {
    if (!inputNode.classList.contains(errorInputClass)) {
      inputNode.classList.add(errorInputClass);

      const errorMessageNode = document.createElement("span");
      errorMessageNode.classList.add(errorMessageClass);
      errorMessageNode.innerHTML = errorMessage;
      inputNode.parentNode.appendChild(errorMessageNode);
    }
  }

  /**
   * Hides form and renders confirmation
   */
  renderConfirmation() {
    const form = document.getElementById(formID);
    const confirmation = document.getElementById(formID + "-confirmation");

    form.classList.add("hidden");
    confirmation.classList.add("visible");
  }
}
