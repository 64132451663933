window.addEventListener("DOMContentLoaded", init, false);

function init() {
  var floatingLabels = document.getElementsByClassName("floating-label");

  if (floatingLabels.length > 0) {
    var placeholderSupported = checkPlaceholderSupport(); // check if browser supports :placeholder
    for (var i = 0; i < floatingLabels.length; i++) {
      (function (i) {
        initFloatingLabel(floatingLabels[i]);
      })(i);
    }

    function initFloatingLabel(element) {
      if (!placeholderSupported) {
        // :placeholder is not supported -> show label right away

        element
          .getElementsByClassName("form__label")[0]
          .classList.add("form__label--floating");

        return;
      }
      var input = element.getElementsByClassName("form-control")[0];

      input.addEventListener("input", function (event) {
        resetFloatingLabel(element, input);
      });
    }

    function resetFloatingLabel(element, input) {
      // show label if input is not empty
      if (input.value.length > 0) {
        element
          .getElementsByClassName("form__label")[0]
          .classList.add("form__label--floating");
      } else {
        element
          .getElementsByClassName("form__label")[0]
          .classList.remove("form__label--floating");
      }
    }

    function checkPlaceholderSupport() {
      var input = document.createElement("input");
      return "placeholder" in input;
    }
  }
}
